<template>
    <div
        ref="img"
        class="img-animation"
        :class="{ animated: isAnimated }"
        :style="{ animationDelay: delay + 's', animationDuration: duration }"
    >
        <slot></slot>
    </div>
</template>

<script>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default {
    name: 'ImgAnimation',
    props: {
        duration: {
            type: Number,
            default: 1,
        },
        delay: {
            type: Number,
            default: 0.3,
        },
    },
    data() {
        return {
            isAnimated: false,
        }
    },
    mounted() {
        gsap.registerPlugin(ScrollTrigger)

        gsap.to(this.$refs.img, {
            scrollTrigger: {
                trigger: this.$refs.img,
                onEnter: () => (this.isAnimated = true),
                once: true,
            },
        })
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.img-animation {
    @media (max-width: 767px) {
        clip-path: none;
    }
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    animation: 2s 0.3s ease-in-out forwards;
}
.animated {
    @media (max-width: 767px) {
        animation: none;
    }
    animation-name: imgOverlay;
}
@keyframes imgOverlay {
    100% {
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 0% 0);
    }
}
</style>
